<template>
  <div>
    <v-container
      ><v-row>
        <v-col cols="12">
          <h2 class="v-heading text-h4 text-sm-h4 mb-3">
            <a
              href="#material-colors"
              class="text-decoration-none text-right text-md-left"
            >
              #
            </a>
            Material colors
          </h2>
          <p>
            Below is a list of the Material design color palette grouped by
            primary color
          </p>

          <!-- <div
            class="
              v-input
              theme--light
              v-text-field
              v-text-field--single-line
              v-text-field--solo
              v-text-field--is-booted
              v-text-field--enclosed
            "
          >
            <div class="v-input__control">
              <div class="v-input__slot">
                <div class="v-input__prepend-inner">
                  <div class="v-input__icon v-input__icon--prepend-inner">
                    <i
                      aria-hidden="true"
                      class="v-icon notranslate mdi mdi-magnify theme--light"
                    ></i>
                  </div>
                </div>
                <div class="v-text-field__slot">
                  <label
                    for="input-121"
                    class="v-label theme--light"
                    style="left: 0px; right: auto; position: absolute"
                    >Search</label
                  ><input id="input-121" type="text" value="" />
                </div>
                <div class="v-input__append-inner">
                  <div class="v-input__icon v-input__icon--append">
                    <i
                      aria-hidden="true"
                      class="v-icon notranslate mdi mdi-palette theme--light"
                    ></i>
                  </div>
                </div>
              </div>
              <div class="v-text-field__details">
                <div class="v-messages theme--light">
                  <div class="v-messages__wrapper"></div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="container pa-0 container--fluid">
            <div class="row">
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 red"
                >
                  <div class="v-card__text">
                    <span class="text-h6">red</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#F44336</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFEBEE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFCDD2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EF9A9A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E57373</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EF5350</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E53935</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D32F2F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C62828</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B71C1C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF8A80</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF5252</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF1744</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 red accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>red </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D50000</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 pink"
                >
                  <div class="v-card__text">
                    <span class="text-h6">pink</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#E91E63</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FCE4EC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F8BBD0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F48FB1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F06292</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EC407A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D81B60</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C2185B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#AD1457</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#880E4F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF80AB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF4081</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F50057</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 pink accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>pink </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C51162</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 purple"
                >
                  <div class="v-card__text">
                    <span class="text-h6">purple</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#9C27B0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F3E5F5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E1BEE7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#CE93D8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#BA68C8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#AB47BC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#8E24AA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#7B1FA2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#6A1B9A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#4A148C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EA80FC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E040FB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D500F9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 purple accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>purple </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#AA00FF</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 deep-purple"
                >
                  <div class="v-card__text">
                    <span class="text-h6">deep-purple</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#673AB7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EDE7F6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D1C4E9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B39DDB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#9575CD</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#7E57C2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#5E35B1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#512DA8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#4527A0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#311B92</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B388FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#7C4DFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#651FFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-purple accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-purple </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#6200EA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 indigo"
                >
                  <div class="v-card__text">
                    <span class="text-h6">indigo</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#3F51B5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E8EAF6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C5CAE9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#9FA8DA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#7986CB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#5C6BC0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#3949AB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#303F9F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#283593</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#1A237E</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#8C9EFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#536DFE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#3D5AFE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 indigo accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>indigo </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#304FFE</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 blue"
                >
                  <div class="v-card__text">
                    <span class="text-h6">blue</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#2196F3</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E3F2FD</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#BBDEFB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#90CAF9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#64B5F6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#42A5F5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#1E88E5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#1976D2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#1565C0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#0D47A1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#82B1FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#448AFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#2979FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#2962FF</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 light-blue"
                >
                  <div class="v-card__text">
                    <span class="text-h6">light-blue</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#03A9F4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E1F5FE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B3E5FC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#81D4FA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#4FC3F7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#29B6F6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#039BE5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#0288D1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#0277BD</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#01579B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#80D8FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#40C4FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00B0FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-blue accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-blue </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#0091EA</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 cyan"
                >
                  <div class="v-card__text">
                    <span class="text-h6">cyan</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#00BCD4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E0F7FA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B2EBF2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#80DEEA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#4DD0E1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#26C6DA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00ACC1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#0097A7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00838F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#006064</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#84FFFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#18FFFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00E5FF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 cyan accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>cyan </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00B8D4</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 teal"
                >
                  <div class="v-card__text">
                    <span class="text-h6">teal</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#009688</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E0F2F1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B2DFDB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#80CBC4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#4DB6AC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#26A69A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00897B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00796B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00695C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#004D40</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#A7FFEB</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#64FFDA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#1DE9B6</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 teal accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>teal </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00BFA5</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 green"
                >
                  <div class="v-card__text">
                    <span class="text-h6">green</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#4CAF50</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E8F5E9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C8E6C9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#A5D6A7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#81C784</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#66BB6A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#43A047</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#388E3C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#2E7D32</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#1B5E20</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B9F6CA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#69F0AE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00E676</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 green accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>green </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#00C853</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 light-green"
                >
                  <div class="v-card__text">
                    <span class="text-h6">light-green</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#8BC34A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F1F8E9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#DCEDC8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C5E1A5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#AED581</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#9CCC65</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#7CB342</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#689F38</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#558B2F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#33691E</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#CCFF90</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B2FF59</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#76FF03</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 light-green accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>light-green </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#64DD17</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 lime"
                >
                  <div class="v-card__text">
                    <span class="text-h6">lime</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#CDDC39</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F9FBE7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F0F4C3</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E6EE9C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#DCE775</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D4E157</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C0CA33</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#AFB42B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#9E9D24</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#827717</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F4FF81</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EEFF41</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#C6FF00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 lime accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>lime </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#AEEA00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 yellow"
                >
                  <div class="v-card__text">
                    <span class="text-h6">yellow</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFEB3B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFFDE7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFF9C4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFF59D</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFF176</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFEE58</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FDD835</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FBC02D</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F9A825</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F57F17</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFFF8D</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFFF00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFEA00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 yellow accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>yellow </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFD600</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 amber"
                >
                  <div class="v-card__text">
                    <span class="text-h6">amber</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFC107</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFF8E1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFECB3</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFE082</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFD54F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFCA28</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFB300</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFA000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF8F00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF6F00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFE57F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFD740</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFC400</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 amber accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>amber </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFAB00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 orange"
                >
                  <div class="v-card__text">
                    <span class="text-h6">orange</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF9800</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFF3E0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFE0B2</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFCC80</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFB74D</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFA726</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FB8C00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F57C00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EF6C00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E65100</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFD180</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFAB40</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF9100</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 orange accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>orange </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF6D00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 deep-orange"
                >
                  <div class="v-card__text">
                    <span class="text-h6">deep-orange</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF5722</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FBE9E7</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFCCBC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFAB91</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF8A65</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF7043</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F4511E</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E64A19</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D84315</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#BF360C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange accent-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>accent-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF9E80</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange accent-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>accent-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF6E40</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange accent-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>accent-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FF3D00</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 deep-orange accent-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>deep-orange </span><span>accent-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#DD2C00</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 brown"
                >
                  <div class="v-card__text">
                    <span class="text-h6">brown</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#795548</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EFEBE9</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#D7CCC8</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#BCAAA4</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#A1887F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#8D6E63</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#6D4C41</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#5D4037</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#4E342E</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 brown darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>brown </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#3E2723</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 blue-grey"
                >
                  <div class="v-card__text">
                    <span class="text-h6">blue-grey</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#607D8B</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#ECEFF1</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#CFD8DC</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#B0BEC5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#90A4AE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#78909C</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#546E7A</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#455A64</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#37474F</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 blue-grey darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>blue-grey </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#263238</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 grey"
                >
                  <div class="v-card__text">
                    <span class="text-h6">grey</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey base"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span
                        ><!---->
                      </div>
                      <div class="text-right col col-4">
                        <span>#9E9E9E</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey lighten-5"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>lighten-5</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FAFAFA</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey lighten-4"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>lighten-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#F5F5F5</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey lighten-3"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>lighten-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#EEEEEE</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey lighten-2"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>lighten-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#E0E0E0</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey lighten-1"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>lighten-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#BDBDBD</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey darken-1"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>darken-1</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#757575</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey darken-2"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>darken-2</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#616161</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey darken-3"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>darken-3</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#424242</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 grey darken-4"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <span>grey </span><span>darken-4</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#212121</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-lg-4 col-12">
                <div
                  class="v-card v-sheet v-sheet--outlined theme--light rounded-0 shades"
                >
                  <div class="v-card__text">
                    <span class="text-h6">shades</span>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 shades black"
                >
                  <div class="v-card__text white--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <!----><span>black</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#000000</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 shades white"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <!----><span>white</span>
                      </div>
                      <div class="text-right col col-4">
                        <span>#FFFFFF</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="v-card v-card--flat v-sheet theme--light rounded-0 shades transparent"
                >
                  <div class="v-card__text black--text">
                    <div class="row">
                      <div class="text-caption col col-8">
                        <!----><span>transparent</span>
                      </div>
                      <div class="text-right col col-4"><!----></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row></v-container
    >
  </div>
</template>

<script>
export default {};
</script>
